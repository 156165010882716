export const appCdnURL = "https://cdn.arora.pro/n/upload/generated/26c85375-f560-43ac-a9ec-1da1e2b1d8ec/b0a82904-0d3d-4a87-94d4-79d8df4d0a5b/dist"

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appHead = {"htmlAttrs":{"lang":"ru"},"link":[{"href":"https://combo-eda.ru/upload/26c85375-f560-43ac-a9ec-1da1e2b1d8ec/file_manager/combo-eda.ru/theme/favicon.png","rel":"icon","type":"image/png"}],"meta":[{"content":"utf-8","name":"charset"},{"content":"P903.313","name":"website-version"},{"content":"no-cache","name":"cache-control"},{"content":"width=device-width, initial-scale=1, maximum-scale=1, shrink-to-fit=no","name":"viewport"},{"content":"#EC1E24","name":"theme-color"},{"content":"food","property":"og:type"},{"content":"https://combo-eda.ru/upload/26c85375-f560-43ac-a9ec-1da1e2b1d8ec/file_manager/combo-eda.ru/theme/popup-mob.jpg","property":"og:image"},{"content":"https://combo-eda.ru/","property":"og:site_name"}]}

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const appSpaLoaderTag = "div"

export const appSpaLoaderAttrs = {"id":"__nuxt-loader"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"prefetch":false,"componentName":"NuxtLink","prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"

export const crawlLinks = true

export const spaLoadingTemplateOutside = false